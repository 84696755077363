const initState = { 
  token: '',
  loading: false
}

export const userInfo = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        token: action.token
      }
    }
    case "LOGOUT": {
      return {
        ...state,
        token: ''
      }
    }
    case "LOADING": {
      return {
        ...state,
        loading: action.payload
      }
    }
    default: {
      return state
    }
  }
}
