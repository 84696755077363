import { combineReducers } from 'redux'
import { userInfo } from './userReducer'
import { modal } from './modalReducer'
import { grids } from './gridReducer'
import { resetFilter} from './resetFilterReducer'
import { reducer as formReducer } from 'redux-form'

export const rootReducer = combineReducers({
  user: userInfo,
  form: formReducer,
  modal,
  grids,
  resetFilter
})