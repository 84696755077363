
import React from 'react';
import { 
  EDIT_ACCOUNT_MODAL,
  GENERATION_URL_MODAL,
  CREATE_ACCOUNT_MODAL
} from '../../components/AccountComponent/constants';
import CreateAdminModal from '../../components/AdminComponent/modals/CreateAdminModal'
import EditPasswordAdminModal from '../../components/AdminComponent/modals/EditPasswordAdminModal'
import DeleteAdminModal from '../../components/AdminComponent/modals/DeleteAdminModal'

import CreateAccountComponentModal from '../../components/AccountComponent/modals/CreateAccountComponentModal';
import GenerationUrlAccountComponentModal from '../../components/AccountComponent/modals/GenerationUrlAccountComponentModal';
import EditAccountComponentModal from '../../components/AccountComponent/modals/EditAccountComponentModal'
import DeleteAccountModal from '../../components/AccountComponent/modals/DeleteAccountModal'

const CREATE_ADMIN_MODAL = 'CREATE_ADMIN_MODAL';
const EDIT_PASSWORD_ADMIN_MODAL = 'EDIT_PASSWORD_ADMIN_MODAL'
const DELETE_ADMIN_MODAL = 'DELETE_ADMIN_MODAL'
const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL'

export const modalsList = {
  // account
  [CREATE_ACCOUNT_MODAL]: <CreateAccountComponentModal />,
  [GENERATION_URL_MODAL]: <GenerationUrlAccountComponentModal />,
  [EDIT_ACCOUNT_MODAL]: <EditAccountComponentModal />,
  [DELETE_ACCOUNT_MODAL]: <DeleteAccountModal />,

  // admin
  [CREATE_ADMIN_MODAL]: <CreateAdminModal />,
  [EDIT_PASSWORD_ADMIN_MODAL]: <EditPasswordAdminModal />,
  [DELETE_ADMIN_MODAL]: <DeleteAdminModal />
};