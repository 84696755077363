import React from 'react'
import './style.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getModalSelector, closeModalController } from './controller'
import { modalsList } from './configModals';


const Modal = ({ contentModal, openModal, closeModal }) => {

  const handleClosePopup = () => {
    closeModal();
  }


  if (!openModal) return null;
  return (
    <div className="backdrop" onClick={handleClosePopup}>
      <div className="modal" onClick={event => event.stopPropagation()}>
        {
          modalsList[contentModal]
        }
      </div>
    </div>
  )
}

Modal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  contentModal: PropTypes.string,
  closeModal: PropTypes.func.isRequired
}

Modal.defaultProps = {
  contentModal: null
}


const enhance = compose(
  connect(state => {
    const modal = getModalSelector(state)
    return {
      contentModal: modal.contentModal,
      openModal: modal.openModal,
    }
  }, {
    closeModal: closeModalController
  })
)

export default enhance(Modal)


