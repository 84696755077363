import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Box, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';


import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const HeadStyledTableCell = ({ titleColumnSort, isSort, currentSortField, width, widthPx = true, titleColumn, sortType, percent = false }) => {
  const isSortIcon = field => (
    currentSortField === field ? (
      sortType === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
    ) : <Box width={24} />
  )

  return (
    <StyledTableCell align="center" data-head-name={titleColumnSort} data-sort={isSort} data-sort-active={currentSortField === titleColumnSort}
      style={{
        minWidth: `${widthPx ? `${width}${percent ? '%' : 'px'}` : '0px'}`,
        width: `${width}${percent ? '%' : 'px'}`

      }}>
      <Box display='flex' justifyContent="center">
        <Typography style={{ fontSize: '11px' }}>{titleColumn}</Typography> {isSort && isSortIcon(titleColumnSort)}
      </Box>
    </StyledTableCell>
  )
}

HeadStyledTableCell.propTypes = {
  titleColumnSort: PropTypes.string,
  isSort: PropTypes.bool,
  currentSortField: PropTypes.string,
  width: PropTypes.number,
  titleColumn: PropTypes.string,
  sortType: PropTypes.string,
};

HeadStyledTableCell.defaultProps = {
  titleColumnSort: null,
  isSort: false,
  currentSortField: null,
  width: 100,
  titleColumn: null,
  sortType: null
};

export default HeadStyledTableCell
