import React, { useEffect, useState } from 'react';
import TableAllCurrentSyncBoard from './TableAllCurrentSyncBoard'
import { Box, Button, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from '@helper/Api/api';

const TableAllCurrentSync = ({
  location: {
    state: {
      internalAccountId,
      marketplace
    }
  }
}) => {
  let history = useHistory();
  let { id } = useParams();

  const [shop, setShop] = useState(null)

  const getAccounts = async () => {
    try {
      const res = await axios.get(`/api/v1/odata/accounts`, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
      const shopName = res.value.find(el => el.Id === id)

      setShop(shopName.ProviderAccountName)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getAccounts()
  })

  return (
    <>
      <Box pt={2}>
        <Button variant='contained' color="primary" onClick={() => history.goBack()}
          startIcon={<ArrowBackIcon />}
        >
          <Typography>Back</Typography>
        </Button>
      </Box>
      <Box pt={2}>
        <Typography variant="h5">{shop}</Typography>
      </Box>
      <Box>
        <TableAllCurrentSyncBoard internalAccountId={internalAccountId} marketplace={marketplace} accountId={id} />
      </Box>
    </>
  )
}

export default TableAllCurrentSync
