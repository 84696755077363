import React, { useContext } from 'react'
import './style.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import { Typography, Button, Box } from '@material-ui/core';
import { PopupContext } from '../../../context/popupContext/PopupContext'

export const Popup = ({ type, title, body }) => {
  const { closePopup } = useContext(PopupContext)

  const handleClosePopup = () => {
    closePopup()
  }
  return (
    <div className="backdrop-popup" onClick={handleClosePopup}>
      <div className="modal" onClick={event => event.stopPropagation()}>
          { type === 'success' && <div className="modal-icon modal-icon-success"><CheckCircleOutlineIcon /></div> }
          { type === 'error' && <div className="modal-icon modal-icon-error"><ErrorIcon /></div> }
        <div className="modal-header"><Typography variant="h4">{ title }</Typography></div>
        <div className="modal-body"><Typography variant="h6">{body}</Typography></div>
        <Box py={3} className="modal-btn"><Button onClick={handleClosePopup} variant="contained" color="primary">Ok</Button></Box>
      </div>
    </div>
  )
}


