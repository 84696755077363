import React from 'react';
import { Field } from 'redux-form'
import SelectFieldFieldConnected from "./SelectFieldFieldConnected";

const SelectField = ({
  name,
  ...restProps
}) => (
  <>
    <Field
      name={name}
      component={SelectFieldFieldConnected}
      {...restProps}
    />
  </>
);

export default SelectField;
