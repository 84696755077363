import axios from '@helper/Api/api';

const checkAuthController = (email, password, openPopup, push) => async (dispatch) => {
  try {
    dispatch({ type: 'LOADING', payload: true })
    const [data] = await axios.post('/api/v1/login/login', { login: email, password: password }).then(res => res.data)
    localStorage.setItem('token', data);
    dispatch({ type: "LOGIN", token: data });
    push('/admin/')
    dispatch({ type: 'LOADING', payload: false })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    }
    else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
    dispatch({ type: 'LOADING', payload: false })
  }
}

export {
  checkAuthController
}
