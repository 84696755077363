
const initialState = {
  reset: false
}

export const resetFilter = (state = initialState, action ) => {
  switch(action.type) {
    case 'RESET_FILTER': {
      return {
        ...state,
        reset: action.payload
      }
    }
    default: return state
  }
}