import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthorizationPage from '../authorization/AuthorizationPage'
import AdminPage from '../admin/AdminPage';
import PrivateRoute from '../../helper/PrivateRoute/PrivateRoute'

// import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { getTokenSelector } from './controller'


// const AuthorizationPage = React.lazy(() => import('../authorization/AuthorizationPage'));
// const PrivateRoute = React.lazy(() => import('../../helper/PrivateRoute/PrivateRoute'));
// const AdminPage = React.lazy(() => import('../admin/AdminPage'));

export const RootPages = ({ token }) => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          {/* <React.Suspense fallback={<div className="loading"><CircularProgress /></div>}> */}
            <Route path="/auth" component={AuthorizationPage} />
            <PrivateRoute path="/admin" component={ ()=>(<AdminPage/>) } />
            <Route path="/*" component={() => <Redirect to="/auth" />} />
          {/* </React.Suspense> */}
        </Switch>
      </BrowserRouter>
    </>
  )
}

const enhance = compose(
  connect(state => {
    const token = getTokenSelector(state)
    return {
      token
    }
  })
)

export default enhance(RootPages)
