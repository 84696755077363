import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import { Box, TableCell, TableRow, IconButton } from '@material-ui/core'

import Tooltip from '@material-ui/core/Tooltip';

import PropTypes from 'prop-types'

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& td': {
      width: '15%'
    }
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const TableBodyComponent = ({ items, openModal }) => {
  const editPassword = id => {
    openModal({nameModal: 'EDIT_PASSWORD_ADMIN_MODAL', props: { id }})
  }

  const delAdmin = id => {
    openModal({nameModal: 'DELETE_ADMIN_MODAL', props: { id }})
  }

  return (
    <>
      {items && items.map(({ Id, Login}) => (
        <StyledTableRow key={Id}>
          <StyledTableCell align="center" >{Login}</StyledTableCell>
          <StyledTableCell align="right">
            <Box display="flex">
              <Box pr={1}>
                <Tooltip title="Edit" arrow>
                  <IconButton color="primary" aria-label="search" component="span" onClick={() => editPassword(Id)} style={{ padding: 5}}>
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box pr={1}>
                <Tooltip title="Delete" arrow>
                  <IconButton color="primary" aria-label="search" component="span" onClick={() => delAdmin(Id)} style={{ padding: 5}}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  )
}

TableBodyComponent.propTypes = {
  items: PropTypes.array,
  openModal: PropTypes.func.isRequired,
}

TableBodyComponent.defaultProps = {
  items: []
}

export default TableBodyComponent;
