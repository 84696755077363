
export const Validate = {
  required: value => (value || typeof value === 'number' ? undefined : 'Required'),
  email: value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined,
  compareNewOldPasswords: (newPassword, oldPassword) => newPassword !== oldPassword ? 'Password mismatch' : undefined,
  positiveNumber: value => Number(value) >= 0 && Number(value) <= 2147483647 ? undefined : 'Invalid value'
}

export const Normalize = {
  trim: value => value && value.trim(),
  toLowerCaseTrim: value => value && value.trim().toLowerCase(),
  number: value => value && value.replace(/\D/, '')
}
