import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core/';

const TableLoader = () => (
  <Grid container justifyContent="center" style={{ minHeight: 80 }}>
    <CircularProgress color="primary" />
  </Grid>
);

export default TableLoader;
