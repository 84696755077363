import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  bodyItem: {
    padding: 5,
    textAlign: 'center'
  },
  bodyRow: {
    transition: 'all .3s',
    '&:hover': {
      background: '#f0f0f3'
    }
  }
}))


const SubOneTableItem = ({ Id, Timestamp, Status, Duration, TransactionsQuantity, Error }) => {
  const classes = useStyles()
  return (
    <tr>
      <td colSpan={5} style={{ padding: 0 }}>
        <Grid container className={classes.bodyRow}>
          <Grid item xs={12}>
            <Grid item xs={12} style={{ borderBottom: '1px solid #c3bebe' }}>
              <Grid container justifyContent="space-around" alignItems="center" style={{ background: Status === 'Failed' ? '#edb7b7' : 'none' }}>
                <Grid item className={classes.bodyItem} style={{ width: '20%' }}><Typography variant="body2">{moment(Timestamp).format('YYYY-MM-DD HH:mm:ss Z')}</Typography></Grid>
                <Grid item className={classes.bodyItem} style={{ width: '20%' }}><Typography variant="body2">{Status}</Typography></Grid>
                <Grid item className={classes.bodyItem} style={{ width: '15%' }}><Typography variant="body2">{(Duration / 1000).toFixed(1)}</Typography></Grid>
                <Grid item className={classes.bodyItem} style={{ width: '15%' }}>
                  <Link to={`/admin/account/transaction-currency/${Id}`}>
                    <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                      {TransactionsQuantity ? (
                        <>
                          {TransactionsQuantity}
                        </>
                      ) : null}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item className={classes.bodyItem} style={{ width: '30%' }}><Typography variant="body2">{Error}</Typography></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </td>
    </tr>
  )
}

SubOneTableItem.propTypes = {
  currentKey: PropTypes.string,
}

SubOneTableItem.defaultProps = {
  currentKey: null
}

export default SubOneTableItem
