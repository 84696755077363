import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";

const SelectFieldFieldConnected = props => {
  const { options } = props
  const { input } = props;
  const { onChange, value } = input
  return (
    <Select
      variant="outlined"
      fullWidth
      value={value}
      onChange={(value) => onChange(value)}
    >
      {
        options.map(({ label, value }) => {
          return (
            <MenuItem value={value} key={value}>{label}</MenuItem>
          )
        })
      }
    </Select>
  );
};

export default SelectFieldFieldConnected;
