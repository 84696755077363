import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core'

const CheckAll = ({ checkedAll, isAllChecked }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Checkbox
        checked={isAllChecked}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        onChange={checkedAll}
      /> 
    </Box>

    
  )
}

export default CheckAll