// grid
const INIT_GRID = 'INIT_GRID'
const LOADING_GRID = 'LOADING_GRID'
const UPDATE_GRID = 'UPDATE_GRID'

//modal
const INIT_MODAL = 'INIT_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

export {
  INIT_GRID,
  LOADING_GRID,
  UPDATE_GRID,

  INIT_MODAL,
  CLOSE_MODAL
}