import { rootReducer } from './reducer'

import {
  applyMiddleware,
  compose,
    createStore
} from 'redux';
import ReduxThunk from 'redux-thunk';

// import popupsController from 'controllers/popups/popupsController';


const createReduxStore = () => {
  let composeFunction;
  const middlewares = [ReduxThunk];
  // const middlewares = [ReduxThunk.withExtraArgument({ popupsController: popupsController.popupsController })];

  /* eslint-disable */
  if (process.env.NODE_ENV !== 'production' && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    composeFunction = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    /* eslint-enable */
  } else {
    composeFunction = compose;
  }
  return createStore(rootReducer, composeFunction(applyMiddleware(...middlewares)));
};

const store = createReduxStore();
const { dispatch, getState } = store;

export { dispatch, getState, store};