import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import moment from 'moment'

const useStyles = makeStyles(() => ({
  bodyItem: {
    width: 150,
    padding: 5,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& p': {
      fontSize: 10
    }
  },
  bodyRow: {
    minHeight: 35,
    transition: 'all .3s',
    '&:hover': {
      background: '#f0f0f3'
    }
  }
}))

const TableFailedTransactionsTableItem = ({ Id, Paid, InternalAccountId, Status, CurrencyCode, AmountGross, handleCheck,
  TransactionId, ListingId, OrderId, ReceiptId, checkboxes,
  ProviderAccountName, CustomerEmail, CustomerName, Title, UploadRetries, UploadAttempt, Error }) => {

  const classes = useStyles();
  return (
    <tr>
      <td colSpan={16} style={{ padding: 0 }}>
        <Grid container className={classes.bodyRow}>
          <Grid item xs={12} style={{ borderBottom: '1px solid #c3bebe' }} >
            <Grid container justifyContent="space-around" alignItems="center" style={{ background: Status === 'Failed' ? '#edb7b7' : 'none' }}>
              <Grid item className={classes.bodyItem} style={{ textAlign: 'center', width: '50px' }}>
                <Checkbox
                  style={{ padding: 0 }}
                  color="primary"
                  checked={checkboxes?.[Id] || false}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={e => handleCheck(e, Id)}
                />
              </Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2">{moment(Paid).format('YYYY-MM-DD HH:mm:ss Z')}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2">{InternalAccountId}</Typography></Grid>
              <Grid item className={classes.bodyItem} style={{ textAlign: 'center', width: '80px' }}><Typography variant="body2">{Status}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2">{TransactionId}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2">{ListingId}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2">{OrderId}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2">{ReceiptId}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2" style={{ textAlign: 'left', color: Number(AmountGross) > 0 ? 'green' : 'red' }}>{AmountGross + ' ' + CurrencyCode} </Typography></Grid>
              <Grid item className={classes.bodyItem} style={{ textAlign: 'center', width: '200px' }}><Typography variant="body2">{ProviderAccountName}</Typography></Grid>
              <Grid item className={classes.bodyItem} style={{ textAlign: 'center', width: '200px' }}><Typography variant="body2">{CustomerEmail}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2">{CustomerName}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2" title={Title}>{Title}</Typography></Grid>
              <Grid item className={classes.bodyItem} style={{ textAlign: 'center', width: '100px' }}><Typography variant="body2">{UploadRetries}</Typography></Grid>
              <Grid item className={classes.bodyItem} style={{ textAlign: 'center', width: '100px' }}><Typography variant="body2">{UploadAttempt}</Typography></Grid>
              <Grid item className={classes.bodyItem}><Typography variant="body2">{Error}</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>
      </td>
    </tr>
  )
}

TableFailedTransactionsTableItem.propTypes = {
  Id: PropTypes.string,
  Paid: PropTypes.string,
  Status: PropTypes.string,
  CurrencyCode: PropTypes.string,
  AmountGross: PropTypes.number,
  handleCheck: PropTypes.func.isRequired
}

TableFailedTransactionsTableItem.defaultProps = {
  Id: null,
  Paid: null,
  Status: null,
  CurrencyCode: null,
  AmountGross: null,
}

export default TableFailedTransactionsTableItem;
