import React, { useContext } from 'react';
import { Button, Box, Typography } from '@material-ui/core';

import { dispatch } from '@store/store'
import DeleteIcon from '@material-ui/icons/Delete';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types'


import { deleteAdminController } from '../controller'
import { PopupContext } from '@context/PopupContext';

const CLOSE_MODAL = 'CLOSE_MODAL';

const DeleteAdminModal = ({ deleteAdmin, modalProps: { id } }) => {
  const { openPopup } = useContext(PopupContext)

  const handleDelete = (e) => {
    e.preventDefault();
    deleteAdmin(openPopup, id)
  }

  const closeModal = () => {
    dispatch({type: CLOSE_MODAL});
  }

  return (
    <div>
      <Typography variant={'h4'}>Delete Admin</Typography>
      <Box p={4}>
        <form onSubmit={handleDelete}>
          <Box pt={2} display="flex" justifyContent="center">
          <Box pr={2}><Button variant="contained" color="secondary" onClick={closeModal}>Cancel</Button></Box>
          <Box><Button variant="contained" color="primary" type="submit" startIcon={<DeleteIcon />} onClick={handleDelete}>Delete</Button></Box>
          </Box>
        </form>
      </Box>
    </div>
  )
}

DeleteAdminModal.propTypes = {
  deleteAdmin: PropTypes.func.isRequired,
  modalProps: PropTypes.shape()
}

DeleteAdminModal.defaultProps = {
  modalProps: {}
}

const enhance = compose(
  connect(state => {
    return {
      modalProps: state.modal.props
    }
  }, {
    deleteAdmin: deleteAdminController
  })
)
export default enhance(DeleteAdminModal)
