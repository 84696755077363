import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'

import Pagination from '@helper/Pagination/Pagination';
import TableLoader from '@helper/Table/components/TableLoader';

import TableBodyComponent from './TableBodyComponent';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { openModalController, loadDataAdminsController, closeGridController, getGridById, updateListAdminController } from '../../controller';

import { ADMIN_LIST_GRID } from '../../constants';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import SearchTextField from '@helper/SearchTextField/SearchTextField'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px'
  },
  table: {
    minWidth: 700,
    '& td, th': {
      padding: '5px 0',
    }
  },
  hoverHead: {
    transition: 'all .3s',
    '& th': {
      transition: 'all .3s',
    },
    '& th[data-sort=true]:hover': {
      cursor: 'pointer',
      background: '#8d97ce'
    },
    '& th[data-sort-active=true]': {
      background: '#8d97ce'
    }
  }
}));

const AdminTable = ({ openModal, loadDataAdmins, items, closeGrid, updateListAdmin, countItems, isLoading }) => {
  const classes = useStyles();
  const [sort, setSort] = useState({ field: 'Login', type: 'asc' })
  const [pagination, setPagination] = useState({ top: 5, skip: 0 })
  const [page, setPage] = useState(1)

  const startQuery = `?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.skip}&$count=true`

  const handleClickHead = (e) => {
    const th = e.target.closest('th')
    if (th.hasAttribute('data-sort')) {
      setSort({ field: th.getAttribute('data-head-name'), type: toggleSort(sort.type) })
      updateListAdmin(`?$orderby=${th.getAttribute('data-head-name')} ${toggleSort(sort.type)}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true`)
    }
  }

  const toggleSort = type => {
    const types = { asc: 'desc', desc: 'asc' };
    return types[type];
  };

  const isSortIcon = field => (
    sort.field === field ? (
      sort.type === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
    ) : <Box width={24} />
  )

  const handleChangePagination = (e, page) => {
    setPage(page)
    setPagination({ ...pagination, skip: pagination.top * page - pagination.top })
    updateListAdmin(`?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true`)
  }

  useEffect(() => {
    loadDataAdmins(startQuery)
    return () => {
      closeGrid({ gridId: ADMIN_LIST_GRID });
    }
  }, [])

  useEffect(() => {
    setSort({ field: 'Login', type: 'asc' })
    setPagination({ top: 5, skip: 0 })
    setPage(1)
  }, [countItems])

  const [uf, setUf] = useState('')
  const updateFilter = (value, filter, filed) => {
    setUf(state => ({ ...state, [filed]: { type: filter, value: value } }))

    const objFilters = { ...uf, [filed]: { type: filter, value: value } }
    let arr = [];
    for (const key in objFilters) {
      var value = objFilters[key].value;

      if ((!value && objFilters[key].value !== 0)
        || objFilters[key].value === `'All'`) {
        continue
      }

      if (typeof value === 'string' || value instanceof String) {
        value = "'" + value.replace(/^'+|'+$/gi, '') + "'";
      }

      if (objFilters[key].type === 'contains') {
        arr.push(`contains(${key}, ${value})`)
      } else {
        arr.push(`${key} ${objFilters[key].type} ${value}`)
      }
    }

    const doneStringFilter = arr.join(' and ')
    updateListAdmin(
      `?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true`
      + `${doneStringFilter ? `&$filter=${encodeURIComponent(doneStringFilter)}` : ''}`)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.hoverHead} onClick={handleClickHead}>
              <StyledTableCell align="center" data-head-name="Login" data-sort={true} data-sort-active={sort.field === 'Login'} style={{ width: '50%' }}>
                <Box display='flex' justifyContent="center">
                  <Typography>login</Typography> {isSortIcon('Login')}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '50%' }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <tr>
              <td>
                <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'Login')} filterString="string" />
              </td>
              <td></td>
            </tr>
            {
              isLoading ? (
                <tr>
                  <td colSpan={2}>
                    <Box py={5}><TableLoader /></Box>
                  </td>
                </tr>
              ) : (
                <TableBodyComponent
                  openModal={openModal}
                  items={items}
                />
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Box pt={5}>
        <Pagination top={pagination.top} skip={pagination.skip} totalCount={countItems} onChange={handleChangePagination} />
      </Box>
    </>
  );
}

AdminTable.propTypes = {
  openModal: PropTypes.func.isRequired,
  loadDataAdmins: PropTypes.func.isRequired,
  closeGrid: PropTypes.func.isRequired,
  updateListAccount: PropTypes.func,
  countItems: PropTypes.number,
  isLoading: PropTypes.bool,
};

AdminTable.defaultProps = {
  items: [],
  countItems: 0,
  isLoading: false,
  updateListAccount: () => { }
};

const enhance = compose(

  connect(state => {
    const { items, countItems, isLoading } = getGridById(state, { gridId: ADMIN_LIST_GRID })
    return {
      items,
      countItems,
      isLoading
    }
  }, {
    openModal: openModalController,
    loadDataAdmins: loadDataAdminsController,
    closeGrid: closeGridController,
    updateListAdmin: updateListAdminController
  })
)

export default enhance(AdminTable)
