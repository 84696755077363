import React from 'react';
import SubOne from '@components/AccountComponent/components/TableAccount/subComponents/SubOne'
import { Box, Button, Typography } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const TableSynchronization = ({ match: { params: { id }}}) => {
  let history = useHistory();
  return (
    <>
    <Box pt={2}>
    <Button variant='contained' color="primary" onClick={() => history.goBack()}
      startIcon={<ArrowBackIcon />}
    >
      <Typography>Back</Typography>
    </Button>
  </Box>
    <SubOne id={id} />
    </>
 )
}

export default TableSynchronization
