import React, { useContext, useState } from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import TextField from '@helper/reduxForm/TextField/TextField'

import { dispatch } from '@store/store'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types'

import { reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'

import { editPasswordAdminController } from '../controller'
import { PopupContext } from '@context/PopupContext';
import { Validate, Normalize } from '@helper/Validate';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';


const CLOSE_MODAL = 'CLOSE_MODAL';

const EditPasswordAdminModal = ({ editPasswordAdmin, values , formSyncErrors, modalProps: { id } }) => {
  const { openPopup } = useContext(PopupContext)

  const isDisabledButton = !!(formSyncErrors && Object.keys(formSyncErrors).length);


  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showRePassword, setShowRePassword] = useState(false);
  const handleClickShowRePassword = () => {
    setShowRePassword(!showRePassword);
  };

  const edit = (e) => {
    e.preventDefault();
    editPasswordAdmin(openPopup, id, values)
  }

  const closeModal = () => {
    dispatch({type: CLOSE_MODAL});
  }

  return (
    <div>
      <Typography variant={'h4'}>Edit password</Typography>
      <Box p={2}>
        <form onSubmit={edit}>
          <Box pb={1}>
            <TextField 
              name="password" 
              label="password" 
              variant="outlined" 
              fullWidth 
              validate={[Validate.required]} 
              normalize={Normalize.trim} 
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box pb={1}>
            <TextField 
              name="repassword" 
              label="repeat password" 
              variant="outlined" 
              fullWidth  
              validate={[Validate.required]} 
              normalize={Normalize.trim} 
              type={showRePassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRePassword}
                      edge="end"
                    >
                      {showRePassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box pt={2} display="flex" justifyContent="center">
            <Box pr={2}><Button variant="contained" color="primary" type="submit" startIcon={<AddCircleIcon />} onClick={edit} disabled={isDisabledButton}>Save</Button></Box>
            <Box><Button variant="contained" color="secondary" onClick={closeModal}>Close</Button></Box>
          </Box>
        </form>
      </Box>
    </div>
  )
}

EditPasswordAdminModal.propTypes = {
  editPasswordAdmin: PropTypes.func.isRequired,
  modalProps: PropTypes.shape()
}

EditPasswordAdminModal.defaultProps = {
  modalProps: {}
}

const enhance = compose(
  reduxForm({
    form: 'EDIT_PASSWORD_ADMIN_FORM',
    initialValues: {
    },
    validate: ({ password, repassword }) => {
      const errors = {};
      const isEqualPasswords = Validate.compareNewOldPasswords(password, repassword);
      if (isEqualPasswords) errors.repassword = isEqualPasswords;
      return errors;
    }
  }),
  connect(state => {
    return {
      values: getFormValues('EDIT_PASSWORD_ADMIN_FORM')(state),
      formSyncErrors: getFormSyncErrors('EDIT_PASSWORD_ADMIN_FORM')(state),
      modalProps: state.modal.props
    }
  }, {
    editPasswordAdmin: editPasswordAdminController
  })
)
export default enhance(EditPasswordAdminModal)
