
import {
  getAccountsListApi,
  deleteAccountApi,
  toggleStatusAccountApi,
  generationUrlAccountApi,
  activateAccountApi,
  editAccountApi,
  createAccountApi,
  createAccountWebhookApi
} from '../api';

import {
  ACCOUNT_LIST_GRID
} from '../constants'

import {
  INIT_GRID,
  LOADING_GRID,
  UPDATE_GRID,
  INIT_MODAL,
  CLOSE_MODAL
} from '@root/constants'

const startQuery = '?$orderby=ProviderAccountId asc&$top=20&$skip=0&$count=true'

const openModalController = ({ nameModal, props }) => (dispatch) => {
  dispatch({ type: INIT_MODAL, payload: { nameModal, props } })
}

const closeModalController = () => (dispatch) => {
  dispatch({ type: CLOSE_MODAL })
}

const loadDataAccountsController = (query, push) => async (dispatch) => {
  try {
    dispatch({ type: INIT_GRID, payload: { gridId: ACCOUNT_LIST_GRID, query: query } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: true } })
    const loadedItemsAdmin = await getAccountsListApi(query);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'] } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: "LOGOUT" })
      localStorage.removeItem('token');
      push('/auth')
    }
  }
}

const createAccountController = (openPopup, data) => async (dispatch, getState) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    await createAccountApi(data)

    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: true } })

    const loadedItemsAdmin = await getAccountsListApi(startQuery);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'], query: startQuery }, })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })

    // reset filter
    dispatch({ type: 'RESET_FILTER', payload: true });

    dispatch({ type: CLOSE_MODAL });

    openPopup({ type: 'success', title: 'Success', body: 'Account created' })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
  dispatch({ type: 'LOADING', payload: false })
}

const ActivateHookController = (openPopup, data) => async (dispatch, getState) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    await createAccountWebhookApi(data.id, data.marketplace)

    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: true } })

    const loadedItemsAdmin = await getAccountsListApi(startQuery);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'], query: startQuery }, })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })

    // reset filter
    dispatch({ type: 'RESET_FILTER', payload: true });

    dispatch({ type: CLOSE_MODAL });

    openPopup({ type: 'success', title: 'Success', body: '' })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
  dispatch({ type: 'LOADING', payload: false })
}

const deleteAccountController = (guid, openPopup) => async (dispatch) => {
  try {
    await deleteAccountApi(guid)
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: true } })
    const loadedItemsAdmin = await getAccountsListApi(startQuery);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'], query: startQuery }, })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })

    dispatch({ type: 'RESET_FILTER', payload: true });

    dispatch({ type: CLOSE_MODAL });
    openPopup({ type: 'success', title: 'Success', body: 'Account deleted' })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
}

const toggleEnableController = (status, marketplace, guid) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: true } })
    if (!status) {
      await toggleStatusAccountApi(guid, marketplace, 'enable')
    } else {
      await toggleStatusAccountApi(guid, marketplace, 'disable')
    }

    const state = getState();
    const currentGrid = state.grids.find(({ gridId }) => gridId === ACCOUNT_LIST_GRID)
    const { query } = currentGrid

    const loadedItemsAdmin = await getAccountsListApi(query);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'] } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })

  } catch (err) {
    console.error(err)
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })
  }
}

const getGenerationUrlController = (openPopup, id, marketplace) => async (dispatch, getState) => {
  dispatch({ type: 'LOADING', payload: true })
  let url = null;
  try {
    url = await generationUrlAccountApi(id, marketplace)

    const state = getState();
    const currentGrid = state.grids.find(({ gridId }) => gridId === ACCOUNT_LIST_GRID)
    const { query } = currentGrid

    const loadedItemsAdmin = await getAccountsListApi(query);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'] } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })

  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
  dispatch({ type: 'LOADING', payload: false })
  return url
}

const activateAccountController = (openPopup, { id, code }) => async (dispatch, getState) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    await activateAccountApi(id, code)
    const state = getState();
    const currentGrid = state.grids.find(({ gridId }) => gridId === ACCOUNT_LIST_GRID)
    const { query } = currentGrid

    const loadedItemsAdmin = await getAccountsListApi(query);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'] } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })

    dispatch({ type: CLOSE_MODAL });
    openPopup({ type: 'success', title: 'Success', body: 'Account activated' })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
  dispatch({ type: 'LOADING', payload: false })
}

const editAccountController = (openPopup, id, marketplace, data) => async (dispatch, getState) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    await editAccountApi(id, marketplace, data)
    dispatch({ type: CLOSE_MODAL });
    openPopup({ type: 'success', title: 'Success', body: 'Account edited' })

    const state = getState();
    const currentGrid = state.grids.find(({ gridId }) => gridId === ACCOUNT_LIST_GRID)
    const { query } = currentGrid

    const loadedItemsAdmin = await getAccountsListApi(query);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'] } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
  dispatch({ type: 'LOADING', payload: false })
}

const updateListAccountController = str => async (dispatch) => {
  try {
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: true } })
    const loadedItemsAdmin = await getAccountsListApi(str);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'], query: str } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

export {
  openModalController,
  closeModalController,

  loadDataAccountsController,

  createAccountController,
  deleteAccountController,
  toggleEnableController,
  getGenerationUrlController,
  activateAccountController,
  editAccountController,
  updateListAccountController,
  ActivateHookController
}
