import React from 'react'
import RootPages from '@pages/root/root';
import { PopupProvider } from '@context/PopupContextProvider'
import Modal from '@helper/Modal'
import Loader from '@helper/Loader'

const App = () => {
  return (
    <PopupProvider>
      <Loader />
      <Modal />
      <RootPages />
    </PopupProvider>
  )
}

export default App


