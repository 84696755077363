import { ADMIN_LIST_GRID } from '../constants'

import {
  INIT_GRID,
  LOADING_GRID,
  UPDATE_GRID,
  INIT_MODAL,
  CLOSE_MODAL
} from '@root/constants'

import {
  getAdminsListApi,
  createAdminApi,
  editPasswordAdminApi,
  deleteAdminApi
} from '../api'

const startQuery = '?$orderby=Login asc&$top=5&$skip=0&$count=true';

const openModalController = ({ nameModal, props }) => dispatch => {
  dispatch({ type: INIT_MODAL, payload: { nameModal, props } })
}

const closeModalController = () => dispatch => dispatch({ type: CLOSE_MODAL })

const loadDataAdminsController = query => async dispatch => {
  try {
    dispatch({ type: INIT_GRID, payload: { gridId: ADMIN_LIST_GRID, query: query } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ADMIN_LIST_GRID, isLoading: true } })
    const loadedDataAdmin = await getAdminsListApi(query);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ADMIN_LIST_GRID, items: loadedDataAdmin.value, countItems: loadedDataAdmin['@odata.count'] } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ADMIN_LIST_GRID, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

const createAdminController = (openPopup, data) => async dispatch => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    await createAdminApi(data)
    dispatch({ type: LOADING_GRID, payload: { gridId: ADMIN_LIST_GRID, isLoading: true } })
    const loadedDataAdmin = await getAdminsListApi(startQuery);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ADMIN_LIST_GRID, items: loadedDataAdmin.value, countItems: loadedDataAdmin['@odata.count'], query: startQuery } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ADMIN_LIST_GRID, isLoading: false } })

    // reset filter
    dispatch({ type: 'RESET_FILTER', payload: true });

    dispatch({ type: CLOSE_MODAL });
    openPopup({ type: 'success', title: 'Success', body: 'Admin created' })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
  dispatch({ type: 'LOADING', payload: false })
}

const editPasswordAdminController = (openPopup, id, data) => async (dispatch) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    await editPasswordAdminApi(id, data)
    dispatch({ type: CLOSE_MODAL });
    openPopup({ type: 'success', title: 'Success', body: 'Password changed' })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
  dispatch({ type: 'LOADING', payload: false })
}

const deleteAdminController = (openPopup, id) => async (dispatch) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    await deleteAdminApi(id)
    dispatch({ type: INIT_GRID, payload: { gridId: ADMIN_LIST_GRID } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ADMIN_LIST_GRID, isLoading: true } })
    const loadedDataAdmin = await getAdminsListApi(startQuery);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ADMIN_LIST_GRID, items: loadedDataAdmin.value, countItems: loadedDataAdmin['@odata.count'], query: startQuery } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ADMIN_LIST_GRID, isLoading: false } })

    // reset filter
    dispatch({ type: 'RESET_FILTER', payload: true });


    dispatch({ type: CLOSE_MODAL });
    openPopup({ type: 'success', title: 'Success', body: 'Admin deleted' })
  } catch (err) {
    if (err.response.data.__message) {
      openPopup({ type: 'error', title: 'Error', body: err.response.data.__message })
    } else {
      openPopup({ type: 'error', title: 'Error', body: 'Something went wrong. Please try again' })
    }
  }
  dispatch({ type: 'LOADING', payload: false })
}

const updateListAdminController = str => async (dispatch) => {
  try {
    dispatch({ type: LOADING_GRID, payload: { gridId: ADMIN_LIST_GRID, isLoading: true } })
    const loadedDataAdmin = await getAdminsListApi(str);
    dispatch({ type: UPDATE_GRID, payload: { gridId: ADMIN_LIST_GRID, items: loadedDataAdmin.value, countItems: loadedDataAdmin['@odata.count'], query: str } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ADMIN_LIST_GRID, isLoading: false } })

  } catch (err) {
    console.error(err)
  }
}

export {
  openModalController,
  closeModalController,
  loadDataAdminsController,
  createAdminController,
  editPasswordAdminController,
  deleteAdminController,
  updateListAdminController
}
