import React, { useContext, useState } from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import TextField from '@helper/reduxForm/TextField/TextField'

import { dispatch } from '@store/store'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types'

import { reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'

import { createAdminController } from '../controller'
import { PopupContext } from '@context/PopupContext';
import { Validate, Normalize } from '@helper/Validate';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';


const CLOSE_MODAL = 'CLOSE_MODAL';


const CreateAdminModal = ({ createAdmin, values, formSyncErrors }) => {
  const { openPopup } = useContext(PopupContext)

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showRePassword, setShowRePassword] = useState(false);
  const handleClickShowRePassword = () => {
    setShowRePassword(!showRePassword);
  };

  const isDisabledButton = !!(formSyncErrors && Object.keys(formSyncErrors).length);

  const create = () => {
    createAdmin(openPopup, values)
  }

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL });
  }

  return (
    <div>
      <Typography variant={'h4'}>Create new Admin</Typography>
      <Box p={2}>
        <form>
          <Box pb={1}>
            <TextField name="login" label="Email" variant="outlined" fullWidth validate={[Validate.required, Validate.email]} />
          </Box>
          <Box pb={1}>
            <TextField
              name="password"
              label="password"
              variant="outlined"
              fullWidth
              validate={[Validate.required]}
              normalize={Normalize.trim}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box pb={1}>
            <TextField
              name="repassword"
              label="repeat password"
              variant="outlined"
              fullWidth
              validate={[Validate.required]}
              normalize={Normalize.trim}
              type={showRePassword ? 'text' : 'password'}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRePassword}
                      edge="end">
                      {showRePassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box pt={2} display="flex" justifyContent="center">
            <Box pr={2}><Button variant="contained" color="primary" startIcon={<AddCircleIcon />} onClick={create} disabled={isDisabledButton}>Create</Button></Box>
            <Box><Button variant="contained" color="secondary" onClick={closeModal}>Close</Button></Box>
          </Box>
        </form>
      </Box>
    </div>
  )
}

CreateAdminModal.propTypes = {
  createAdmin: PropTypes.func.isRequired
}

const enhance = compose(
  reduxForm({
    form: 'CREATE_ADMIN_FORM',
    initialValues: {
    },
    validate: ({ password, repassword }) => {
      const errors = {};
      const isEqualPasswords = Validate.compareNewOldPasswords(password, repassword);
      if (isEqualPasswords) errors.repassword = isEqualPasswords;
      return errors;
    }
  }),
  connect(state => {
    return {
      values: getFormValues('CREATE_ADMIN_FORM')(state),
      formSyncErrors: getFormSyncErrors('CREATE_ADMIN_FORM')(state)
    }
  }, {
    createAdmin: createAdminController
  })
)
export default enhance(CreateAdminModal)
