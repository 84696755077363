import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  table: {
    minWidth: 700,
    '& td, th': {
      padding: '5px 0',
      border: '1px solid black'
    }
  },
  hoverHead: {
    transition: 'all .3s',
    '& th': {
      transition: 'all .3s',
    },
    '& th[data-sort=true]:hover': {
      cursor: 'pointer',
      background: '#8d97ce'
    },
    '& th[data-sort-active=true]': {
      background: '#8d97ce'
    }
  },
}))