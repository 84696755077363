import React from 'react';
import { Box, Typography, Button } from '@material-ui/core'
import TableAccount from './components/TableAccount/TableAccount'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { openModalController } from './controller';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { CREATE_ACCOUNT_MODAL } from './constants'

 const AccountComponent = ({ openModal, initialValues }) => (
  <>
    <Box py={2}>
      <Button variant="contained" size="small"color="primary" startIcon={<AddCircleIcon />} onClick={() => openModal({nameModal: CREATE_ACCOUNT_MODAL})}>
        <Typography>Create new Account</Typography>
      </Button>
    </Box>
    <Box py={2}>
      <TableAccount 
        initialValues={initialValues}
      />
    </Box>
  </>
)

AccountComponent.propTypes = {
  openModal: PropTypes.func.isRequired
}

AccountComponent.defaultProps = {
  initialValues: {
    fieldSort: 'ProviderAccountId',
    typeSort: 'asc',
    top: 20,
    skip: 0
  }
}

const enhance = compose(
  connect(null, {
    openModal: openModalController
  })
)

export default enhance(AccountComponent)

