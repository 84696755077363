import { getTransactionListApi,
  getSyncListApi } from '@components/AccountComponent/components/TableAccount/subComponents/api'

import {
  INIT_GRID,
  LOADING_GRID,
  UPDATE_GRID,
} from '@root/constants'

import { ACCOUNT_SUB_LIST_GRID,
  ACCOUNT_SYNC_LIST_GRID } from '@components/AccountComponent/components/TableAccount/subComponents/constants'

const loadSubOneController = (accountId, query) => async (dispatch) => {
  try {
    dispatch({type: INIT_GRID, payload: { gridId: `${ACCOUNT_SUB_LIST_GRID}_${accountId}`, query: query} })
    dispatch({type: LOADING_GRID, payload: { gridId:  `${ACCOUNT_SUB_LIST_GRID}_${accountId}`, isLoading: true } })
    const transactionList = await getTransactionListApi(accountId, query);
    dispatch({type: UPDATE_GRID, payload: { gridId:  `${ACCOUNT_SUB_LIST_GRID}_${accountId}`, guid: accountId, items: transactionList.value, countItems: transactionList['@odata.count']} })
    dispatch({type: LOADING_GRID, payload: { gridId:  `${ACCOUNT_SUB_LIST_GRID}_${accountId}`, isLoading: false } })
  } catch (err) {
    console.error(err)
  } 
}
const updateListSubAccountController = (str, accountId) => async (dispatch) => {
  try {
    dispatch({type: LOADING_GRID, payload: { gridId: `${ACCOUNT_SUB_LIST_GRID}_${accountId}`, isLoading: true } })
    const transactionList = await getTransactionListApi(accountId, str);
    dispatch({type: UPDATE_GRID, payload: { gridId: `${ACCOUNT_SUB_LIST_GRID}_${accountId}`, items: transactionList.value, countItems: transactionList['@odata.count'], query: str } })
    dispatch({type: LOADING_GRID, payload: { gridId: `${ACCOUNT_SUB_LIST_GRID}_${accountId}`, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}


const loadSyncController = (synchronizationId, query) => async (dispatch) => {
  try {
    dispatch({type: INIT_GRID, payload: { gridId:  `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}`, query: query} })
    dispatch({type: LOADING_GRID, payload: { gridId:  `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}`, isLoading: true } })
    const syncList = await getSyncListApi(synchronizationId, query);
    dispatch({type: UPDATE_GRID, payload: { gridId:  `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}`, items: syncList.value, countItems: syncList['@odata.count']} })
    dispatch({type: LOADING_GRID, payload: { gridId:  `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}`, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

const updateListSyncAccountController = (str, synchronizationId) => async (dispatch) => {
  try {
    dispatch({type: LOADING_GRID, payload: { gridId: `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}`, isLoading: true } })
    const syncList = await getSyncListApi(synchronizationId, str);
    dispatch({type: UPDATE_GRID, payload: { gridId: `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}`, items: syncList.value, countItems: syncList['@odata.count'], query: str } })
    dispatch({type: LOADING_GRID, payload: { gridId: `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}`, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

export {
  loadSubOneController,
  loadSyncController,
  updateListSubAccountController,
  updateListSyncAccountController
}
