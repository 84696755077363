import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import HelperBtn from './HelperBtn';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PopupContext } from '@context/PopupContext';
import {
  openModalController,
  deleteAccountController,
  toggleEnableController,
  handleForceSyncController,
  ActivateHookController
} from '../../controller';

import {
  GENERATION_URL_MODAL,
  EDIT_ACCOUNT_MODAL
} from '../../constants';

import moment from 'moment';

const useStyles = makeStyles(() => ({
  bodyItem: {
    padding: 5,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 10,
    '& p': {
      fontSize: 10
    }
  },
  bodyRow: {
    transition: 'all .3s',
    '&:hover': {
      background: '#f0f0f3'
    }
  }
}))

const RowBody = ({
  Id: id,
  ProviderAccountId: providerAccountId,
  InternalAccountId: internalAccountId,
  LastSyncTimestamp: lastSyncTimestamp,
  ProviderAccountName,
  InternalAccountType: internalAccountType,
  TokenType: tokenType,
  Enabled: enabled,
  Balance,
  CurrencyCode,
  Marketplace: marketplace,
  openModal,
  toggleEnable,
  handleForceSync,
  ActivateHook,
  TransactionCount }) => {

  const classes = useStyles();
  let history = useHistory();
  const { openPopup } = useContext(PopupContext)
  const handleActivate = id => openModal({ nameModal: GENERATION_URL_MODAL, props: { id, marketplace } })
  const handleEdit = id => openModal({ nameModal: EDIT_ACCOUNT_MODAL, props: { id, marketplace } })
  const handleChangeSwitch = (status, marketplace, guid) => toggleEnable(status, marketplace, guid)

  const handleDeleteAccount = guid => {
    openModal({ nameModal: 'DELETE_ACCOUNT_MODAL', props: { guid } })
  }

  const diffDate = date => {
    return moment.utc(new Date()).diff(moment.utc(date), 'hours')
  }

  const openSyncPage = () => {
    history.push(`/admin/account/sync/${id}`);
  }

  const openTransPage = () => {
    history.push({
      pathname: `/admin/account/transaction/${id}`,
      state: { internalAccountId, marketplace, accountId: id }
    });
  }

  const activate = () => {
    ActivateHook(openPopup, { id, marketplace })
  }

  var rowBackgroundColor = ''
  if (!enabled) {
    rowBackgroundColor = '#989898' // gray
  } else if (tokenType !== 'Permanent') {
    rowBackgroundColor = '#ffffb3' // yellow
  } else if (!lastSyncTimestamp || diffDate(lastSyncTimestamp) > 3) {
    if (!marketplace.includes('Shopify')) {
      rowBackgroundColor = '#d8a1a1' // red
    }
  }

  return (
    <tr>
      <td colSpan={9} style={{ padding: 0 }}>
        <Grid item xs={12} style={{ background: rowBackgroundColor }} className={classes.bodyRow}>
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item className={classes.bodyItem} style={{ width: 200 }}><Typography style={{ textDecoration: !enabled ? 'line-through' : 'none', textAlign: 'center' }} variant="body2">{marketplace}</Typography></Grid>
            <Grid item className={classes.bodyItem} style={{ overflow: 'hidden', width: 300 }}><Typography style={{ textDecoration: !enabled ? 'line-through' : 'none', textAlign: 'left', padding: ' 0 5px' }} variant="body2">{ProviderAccountName}</Typography></Grid>
            <Grid item className={classes.bodyItem} style={{ width: 300 }}><Typography style={{ textDecoration: !enabled ? 'line-through' : 'none', textAlign: 'left', padding: ' 0 5px' }} variant="body2">{providerAccountId}</Typography></Grid>
            <Grid item className={classes.bodyItem} style={{ width: 300 }}><Typography style={{ textDecoration: !enabled ? 'line-through' : 'none', textAlign: 'center', padding: ' 0 5px' }} variant="body2">{internalAccountId}</Typography></Grid>
            <Grid item className={classes.bodyItem} style={{ width: 300 }}>
              <Typography
                style={{
                  textDecoration: !enabled ? 'line-through' : 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 10
                }}
                variant="body2"
              >
                {lastSyncTimestamp ? (
                  <>
                    {moment(lastSyncTimestamp).format('YYYY-MM-DD HH:mm:ss Z')}
                    {' '}
                  </>
                ) : null
                }
              </Typography>
            </Grid>
            <Grid item className={classes.bodyItem} style={{ width: 300 }}><Typography style={{ textDecoration: !enabled ? 'line-through' : 'none', textAlign: 'center' }} variant="body2">{internalAccountType}</Typography></Grid>
            <Grid item className={classes.bodyItem} style={{ width: 150 }}><Typography style={{ textDecoration: !enabled ? 'line-through' : 'none' }} variant="body2">{tokenType}</Typography></Grid>
            <Grid item className={classes.bodyItem} style={{ width: 150 }}>
              <Typography style={{ textDecoration: !enabled ? 'line-through' : 'none' }} variant="body2">
                {
                  Balance || '-'
                }
                {
                  CurrencyCode || ''
                }
                {
                  ' / '
                }
                {
                  TransactionCount
                }
                {
                  ` t.`
                }
              </Typography></Grid>
            <Grid item className={classes.bodyItem} style={{ textAlign: 'center', width: 50 }}>
              <HelperBtn
                marketplace={marketplace}
                activate={activate}
                id={id}
                enabled={enabled}
                tokenType={tokenType}
                handleActivate={id => handleActivate(id)}
                handleEdit={id => handleEdit(id)}
                handleChangeSwitch={(status, marketplace, guid) => handleChangeSwitch(status, marketplace, guid)}
                handleDeleteAccount={guid => handleDeleteAccount(guid)}
                handleForceSync={id => handleForceSync(id)}
                openSyncTable={openSyncPage}
                openTransactionTable={openTransPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </td>
    </tr>

  )
}

RowBody.propTypes = {
  openModal: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  toggleEnable: PropTypes.func.isRequired,

  providerAccountId: PropTypes.string,
  internalAccountId: PropTypes.number,
  lastSyncTimestamp: PropTypes.string,
  internalAccountType: PropTypes.string,
  tokenType: PropTypes.string,
  enabled: PropTypes.bool,
  Marketplace: PropTypes.string.isRequired
}

RowBody.defaultProps = {
  providerAccountId: '',
  internalAccountId: null,
  lastSyncTimestamp: '',
  internalAccountType: '',
  tokenType: '',
  enabled: true,
}

const enhance = compose(
  connect(null, {
    openModal: openModalController,
    deleteAccount: deleteAccountController,
    toggleEnable: toggleEnableController,
    handleForceSync: handleForceSyncController,
    ActivateHook: ActivateHookController
  })
)

export default enhance(RowBody)
