import React from 'react'
import { Box } from '@material-ui/core';
import SubOneTable from './SubOneTable';
import PropTypes from 'prop-types'

const initialValues = {
  fieldSort: 'Timestamp',
  typeSort: 'desc',
  top: 20,
  skip: 0
}

const SubOne = ({ id }) => {
  return ( 
    <Box py={2}>
      <SubOneTable id={id} initialValues={initialValues} />
    </Box>
  )
}

SubOne.propTypes = {
  id: PropTypes.string.isRequired
}

export default SubOne
