import axios from '@helper/Api/api';

const getAccountsListApi = str => axios.get(`/api/v1/odata/accounts${str}`, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const createAccountApi = data => axios.post(`/api/v1/accounts/${data.typeShop}`, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const createAccountWebhookApi = (id, marketplace) => axios.post(`/api/v1/accounts/${marketplace}/${id}/authorize/webhook`, {}, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)

const deleteAccountApi = guid => axios.delete(`/api/v1/accounts/etsy/${guid}`, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const toggleStatusAccountApi = (guid, marketplace, isEnable) => axios.post(`/api/v1/accounts/${marketplace}/${guid}/${isEnable}`, {}, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const generationUrlAccountApi = (id, marketplace) => axios.post(`/api/v1/accounts/${marketplace}/${id}/authorize/temp`, {}, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const activateAccountApi = (id, code) => axios.post(`/api/v1/accounts/etsy/${id}/authorize/permanent`, { verifier: code }, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const editAccountApi = (id, marketplace, currentValues) => axios.post(`/api/v1/accounts/${marketplace}/${id}`, { ...currentValues }, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)

const forceSyncApi = id => axios.post(`/api/v1/accounts/etsy/${id}/force`, {}, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)

const forceTransactionsApi = (items) => axios.post(`/api/v1/transactions/force`, items, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const ignoreTransactionsApi = (items) => axios.post(`/api/v1/transactions/ignore`, items, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)

const getTransactionsApi = (query) => axios.get('/api/v1/odata/accounts/transactions' + query, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)

export {
  getAccountsListApi,
  createAccountApi,
  createAccountWebhookApi,
  deleteAccountApi,
  toggleStatusAccountApi,
  generationUrlAccountApi,
  activateAccountApi,
  editAccountApi,

  forceSyncApi,

  forceTransactionsApi,
  ignoreTransactionsApi,

  getTransactionsApi
}
