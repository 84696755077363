import React, { useContext, useState } from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import TextField from '@helper/reduxForm/TextField/TextField'
import SelectField from '@helper/reduxForm/SelectField/SelectField'
import { dispatch } from '@store/store'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types'

import { reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'

import { createAccountController } from '../controller'
import { PopupContext } from '@context/PopupContext';

import { CREATE_ACCOUNT_FORM } from '../constants'

import { Validate, Normalize } from '@helper/Validate';

import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

const CLOSE_MODAL = 'CLOSE_MODAL';

const CreateAccountComponentModal = ({ createAccount, values, formSyncErrors }) => {
  const { openPopup } = useContext(PopupContext)

  const isDisabledButton = !!(formSyncErrors && Object.keys(formSyncErrors).length);

  const [showKeyString, setShowKeyString] = useState(false);
  const handleClickShowKeyString = () => {
    setShowKeyString(!showKeyString);
  };

  const [showKeyShared, setShowKeyShared] = useState(false);
  const handleClickShowKeyShared = () => {
    setShowKeyShared(!showKeyShared);
  };

  const create = () => {
    createAccount(openPopup, values)
  }

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL });
  }

  return (
    <div>
      <Typography variant={'h4'}>Create new Account</Typography>
      <Box p={2}>
        <form>
          <Box pb={1}>
            <TextField name="providerAccountId" label="Original Name" variant="outlined" fullWidth validate={[Validate.required]} />
          </Box>
          <Box pb={1}>
            <TextField name="providerAccountName" label="New Shop Name" variant="outlined" fullWidth />
          </Box>
          <Box pb={1}>
            <SelectField
              name="typeShop"
              label="Type shop"
              variant="outlined"
              options={[
                { label: 'Etsy', value: 'etsy' },
                { label: 'Shopify (Restricted)', value: 'shopify' },
                { label: 'Shopify (Full)', value: 'shopifyfull' },
                { label: 'eBay', value: 'ebay' }]}
              validate={[Validate.required]}
              fullWidth />
          </Box>

          <Box pb={1}>
            <TextField name="internalAccountId" label="WB client id" variant="outlined" fullWidth validate={[Validate.required, Validate.positiveNumber]} />
          </Box>
          <Box pb={1}>
            <SelectField
              name="internalAccountType"
              label="Type (WB or KOMOK)"
              variant="outlined"
              options={[{ label: 'WB', value: 'WB' }, { label: 'KOMOK', value: 'KOMOK' }]}
              validate={[Validate.required]}
              fullWidth />
          </Box>
          <Box pb={1}>
            <TextField name="UploadRetries" label="Upload Retries" variant="outlined" fullWidth normalize={Normalize.number} validate={[Validate.required, Validate.positiveNumber]} />
          </Box>
          <Box pb={1}>
            <TextField
              name="key1"
              label="KEYSTRING"
              variant="outlined"
              fullWidth
              validate={[Validate.required]}
              normalize={Normalize.trim}
              type={showKeyString ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowKeyString} edge="end">
                      {showKeyString ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box pb={1}>
            <TextField
              name="key2"
              label="SHARED SECRET"
              variant="outlined"
              fullWidth
              validate={[Validate.required]}
              normalize={Normalize.trim}
              type={showKeyShared ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowKeyShared} edge="end">
                      {showKeyShared ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box pt={2} display="flex" justifyContent="center">
            <Box pr={2}><Button variant="contained" color="primary" startIcon={<AddCircleIcon />} onClick={create} disabled={isDisabledButton}>Create</Button></Box>
            <Box><Button variant="contained" color="secondary" onClick={closeModal}>Close</Button></Box>
          </Box>
        </form>
      </Box>
    </div>
  )
}

CreateAccountComponentModal.propTypes = {
  createAccount: PropTypes.func.isRequired
}

const enhance = compose(
  reduxForm({
    form: CREATE_ACCOUNT_FORM,
    initialValues: {
      internalAccountType: 'WB',
      providerAccountName: '',
      typeShop: '',
      UploadRetries: '10'
    }
  }),
  connect(state => {
    return {
      values: getFormValues('CREATE_ACCOUNT_FORM')(state),
      formSyncErrors: getFormSyncErrors('CREATE_ACCOUNT_FORM')(state)
    }
  }, {
    createAccount: createAccountController
  })
)
export default enhance(CreateAccountComponentModal)
