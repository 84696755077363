import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

const SearchSelect = ({ updateFilter, options, reset }) => {
  const [filter, setFilter] = useState(options[0].value);

  const handleChange = (event) => {
    setFilter(event.target.value);
    updateFilter(event.target.value || '', 'eq')
  };

  useEffect(() => {
    if (reset) {
      setFilter(options[0].value)
    }
  }, [reset])

  return (
    <Box px={1}>
      <Select
        fullWidth
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filter}
        onChange={handleChange}>
        {
          options.map((value) => {
            return (
              <MenuItem value={value.value} key={value.value}>{value.label}</MenuItem>
            )
          })
        }
      </Select>
    </Box>
  )
}

SearchSelect.propTypes = {
  updateFilter: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  reset: PropTypes.bool.isRequired
}

SearchSelect.defaultProps = {
  updateFilter: () => { },
  options: []
}

const enhance = compose(
  connect(state => {
    const { reset } = state.resetFilter;
    return {
      reset
    }
  })
)

export default enhance(SearchSelect);
