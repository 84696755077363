import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentToken = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={props => (
        currentToken
          ? <Component {...props} /> 
          : <Redirect to="/auth" />
      )}
    />
  )
}

export default PrivateRoute;