import { forceSyncApi, forceTransactionsApi, ignoreTransactionsApi, getAccountsListApi } from '@components/AccountComponent/api';
import { getSyncListApi } from '@components/AccountComponent/components/TableAccount/subComponents/api'
import { ACCOUNT_LIST_GRID } from '../constants'
import { getTransactionsApi } from '@components/AccountComponent/api'
import { LOADING_GRID, UPDATE_GRID, } from '@root/constants'

const handleForceSyncController = id => async (dispatch, getState) => {
  try {
    await forceSyncApi(id)
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: true } })
    const state = getState();
    const currentGrid = state.grids.find(({ gridId }) => gridId === ACCOUNT_LIST_GRID)
    const { query } = currentGrid
    const loadedItemsAdmin = await getAccountsListApi(query);

    dispatch({ type: UPDATE_GRID, payload: { gridId: ACCOUNT_LIST_GRID, items: loadedItemsAdmin.value, countItems: loadedItemsAdmin['@odata.count'], query: query } })
    dispatch({ type: LOADING_GRID, payload: { gridId: ACCOUNT_LIST_GRID, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

const PushTransactionsController = (items, tableName, query, synchronizationId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_GRID, payload: { gridId: tableName, isLoading: true } })
    await forceTransactionsApi(items)
    let syncList = null
    if (!synchronizationId) {
      syncList = await getTransactionsApi(query);
    } else {
      syncList = await getSyncListApi(synchronizationId, query);
    }

    dispatch({ type: UPDATE_GRID, payload: { gridId: tableName, items: syncList.value, countItems: syncList['@odata.count'] } })
    dispatch({ type: LOADING_GRID, payload: { gridId: tableName, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

const IgnoreTransactionsController = (items, tableName, query, synchronizationId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_GRID, payload: { gridId: tableName, isLoading: true } })
    await ignoreTransactionsApi(items)
    let syncList = null
    if (!synchronizationId) {
      syncList = await getTransactionsApi(query);
    } else {
      syncList = await getSyncListApi(synchronizationId, query);
    }

    dispatch({ type: UPDATE_GRID, payload: { gridId: tableName, items: syncList.value, countItems: syncList['@odata.count'] } })
    dispatch({ type: LOADING_GRID, payload: { gridId: tableName, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

export {
  handleForceSyncController,
  PushTransactionsController,
  IgnoreTransactionsController
}
