import { getTransactionsApi } from '@components/AccountComponent/api'
import {
  INIT_GRID,
  LOADING_GRID,
  UPDATE_GRID,
} from '@root/constants'

const loadTableFailedTransactionsController = (query) => async (dispatch) => {
  try {
    dispatch({type: INIT_GRID, payload: { gridId:  `TableFailedTransactions`, query: query} })
    dispatch({type: LOADING_GRID, payload: { gridId:  `TableFailedTransactions`, isLoading: true } })
    const syncList = await getTransactionsApi(query);
    dispatch({type: UPDATE_GRID, payload: { gridId:  `TableFailedTransactions`, items: syncList.value, countItems: syncList['@odata.count']} })
    dispatch({type: LOADING_GRID, payload: { gridId:  `TableFailedTransactions`, isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

const updateTableFailedTransactionsController = (query) => async (dispatch) => {
  try {
    dispatch({type: LOADING_GRID, payload: { gridId: 'TableFailedTransactions', isLoading: true } })
    const syncList = await getTransactionsApi(query);
    dispatch({type: UPDATE_GRID, payload: { gridId: 'TableFailedTransactions', items: syncList.value, countItems: syncList['@odata.count'], query: query } })
    dispatch({type: LOADING_GRID, payload: { gridId: 'TableFailedTransactions', isLoading: false } })
  } catch (err) {
    console.error(err)
  }
}

export {
  loadTableFailedTransactionsController,
  updateTableFailedTransactionsController
}