import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types'


const Loader = ({ loading }) => {
  return (
    <>
      {
        loading && (
          <div className="backdrop loader">
            <CircularProgress />
          </div>
        )
      }
    </>
  );
}

Loader.propTypes = {
  loading: PropTypes.bool
}

Loader.defaultProps = {
  loading: false
}

const enhance = compose(
  connect(state => {
    return {
      loading: state.user.loading
    }
  })
)

export default enhance(Loader)
