import React, { useState } from 'react';
import { PopupContext } from './PopupContext';
import { Popup } from '../../modules/helper/Popup/Popup'

export const PopupProvider = ({ children }) => {

  const [popupOpened, setPopupOpened] = useState(false)
  const [popupContent, setPopupContent] = useState(false)

  const openPopup = config => {
    setPopupContent(config)
    setPopupOpened(true)
  }

  const closePopup = () => {
    setPopupOpened(false)
  }

  const valuePopupProvider = {
    openPopup,
    closePopup
  }

  return (
    <PopupContext.Provider value={valuePopupProvider}>
      {  popupOpened && <Popup {...popupContent} />}
      { children }
    </PopupContext.Provider>
  )
}


