import axios from "axios";

export const testUrl = 'https://etsy.wbapi.systems'

const development = process.env.NODE_ENV === 'development'; 
const pre_url = development ? testUrl : '';


export default axios.create({
  baseURL: pre_url,
  responseType: "json",
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    // 'authorization': `Bearer ${localStorage.getItem('token')}`,
   },
});