const PROVIDER_ACCOUNT_NAME = 'ProviderAccountName'
const PROVIDER_ACCOUNT_ID = 'ProviderAccountId'
const INTERNAL_ACCOUNT_ID = 'InternalAccountId'
const LAST_SYNC_TIMESTAMP= 'LastSyncTimestamp'
const INTERNAL_ACCOUNT_TYPE= 'InternalAccountType'
const TOKEN_TYPE = 'TokenType'
const BALANCE = 'Balance'
const MARKETPLACE = 'Marketplace'

export {
  PROVIDER_ACCOUNT_NAME,
  PROVIDER_ACCOUNT_ID,
  INTERNAL_ACCOUNT_ID,
  LAST_SYNC_TIMESTAMP,
  INTERNAL_ACCOUNT_TYPE,
  TOKEN_TYPE,
  BALANCE,
  MARKETPLACE
}