import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import {
  loadSubOneController,
  updateListSubAccountController
} from './controller'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Box } from '@material-ui/core'

import Pagination from '@helper/Pagination/Pagination';
import SubOneTableItem from './SubOneTableItem';
import TableLoader from '@helper/Table/components/TableLoader';

import { closeGridController, getGridById } from '@root/controller'
import { ACCOUNT_SUB_LIST_GRID } from '@components/AccountComponent/components/TableAccount/subComponents/constants'

import { toggleSort } from '@root/helperFunctions'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import HeadStyledTableCell from '@components/AccountComponent/components/TableAccount/Template/HeadStyledTableCell'
import { useStyles } from '@components/AccountComponent/styles';

import SearchTextField from '@helper/SearchTextField/SearchTextField'
import SearchSelect from '@helper/SearchSelect/SearchSelect'


const SubOneTable = ({ loadSubOne, closeGrid, id, isLoading, items, countItems, updateListSubAccount,
  initialValues: { fieldSort, typeSort, top, skip } }) => {
  const classes = useStyles();
  const [sort, setSort] = useState({ field: fieldSort, type: typeSort })
  const [pagination, setPagination] = useState({ top: top, skip: skip })
  const [valueStringFilter, setValueStringFilter] = useState(null)

  const startQuery = `?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.skip}&$count=true`


  const handleChangePagination = (e, page) => {
    setPagination({ ...pagination, skip: pagination.top * page - pagination.top })
    updateListSubAccount(`?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true${valueStringFilter ? '&$filter=' + valueStringFilter : ''}`, id)
  }


  const handleClickHead = e => {
    const th = e.target.closest('th')
    if (th.hasAttribute('data-sort') && th.getAttribute('data-sort') === 'true') {
      setSort({ field: th.getAttribute('data-head-name'), type: toggleSort(sort.type) })
      updateListSubAccount(`?$orderby=${th.getAttribute('data-head-name')} ${toggleSort(sort.type)}&$top=${top}&$skip=${skip}&$count=true${valueStringFilter ? '&$filter=' + valueStringFilter : ''}`, id)
      setPagination({ top, skip })
    }
  }

  const [uf, setUf] = useState('')
  const updateFilter = (value, filter, filed) => {
    setUf(state => ({ ...state, [filed]: { type: filter, value: value } }))

    const objFilters = { ...uf, [filed]: { type: filter, value: value } }
    let arr = [];
    for (const key in objFilters) {
      var value = objFilters[key].value;

      if ((!value && objFilters[key].value !== 0)
        || objFilters[key].value === `'All'`) {
        continue
      }

      if (typeof value === 'string' || value instanceof String) {
        value = "'" + value.replace(/^'+|'+$/gi, '') + "'";
      }

      if (objFilters[key].type === 'contains') {
        arr.push(`contains(${key}, ${value})`)
      } else {
        arr.push(`${key} ${objFilters[key].type} ${value}`)
      }
    }

    const doneStringFilter = arr.join(' and ')
    setValueStringFilter(doneStringFilter)
    updateListSubAccount(
      `?$orderby=${sort.field} ${sort.type}&$top=${top}&$skip=${skip}&$count=true`
      + `${doneStringFilter ? `&$filter=${encodeURIComponent(doneStringFilter)}` : ''}`,
      id)
    setPagination({ top, skip })
  }



  useEffect(() => {
    loadSubOne(id, startQuery)
    return () => {
      closeGrid({ gridId: `${ACCOUNT_SUB_LIST_GRID}_${id}` })
    }
  }, [])

  const configHeadTitle = [
    { titleColumnSort: 'Timestamp', isSort: true, currentSortField: sort.field, width: 20, titleColumn: 'Timestamp', sortType: sort.type },
    {
      titleColumnSort: 'Status', isSort: true, currentSortField: sort.field, width: 20, titleColumn: 'Status', sortType: sort.type,
      filterComponent: <SearchSelect updateFilter={(text, filter) => updateFilter(text, filter, 'Status')} options={[
        { label: 'All', value: '' },
        { label: 'New', value: 'New' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Completed', value: 'Completed' },
        { label: 'Failed', value: 'Failed' },
        { label: 'Ignored', value: 'Ignored' }
      ]} />
    },
    {
      titleColumnSort: 'Duration', isSort: true, currentSortField: sort.field, width: 15, titleColumn: 'Duration (sec)', sortType: sort.type
    },
    {
      titleColumnSort: 'TransactionsQuantity', isSort: true, currentSortField: sort.field, width: 15, titleColumn: 'Transactions', sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'TransactionsQuantity')} filterString="number" />
    },
    {
      titleColumnSort: 'Error', isSort: true, currentSortField: sort.field, width: 30, titleColumn: 'Error', sortType: sort.type
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.hoverHead} onClick={handleClickHead}>
              {
                configHeadTitle.map(({ titleColumnSort, isSort, currentSortField, width, titleColumn, sortType }) => {
                  return (
                    <HeadStyledTableCell
                      key={titleColumn}
                      titleColumnSort={titleColumnSort}
                      isSort={isSort}
                      currentSortField={currentSortField}
                      width={width}
                      percent={true}
                      titleColumn={titleColumn}
                      sortType={sortType}
                    />
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <tr>{configHeadTitle.map(({ titleColumn, filterComponent = null }) => <td key={titleColumn}>{filterComponent}</td>)}</tr>
            {
              isLoading ? (
                <tr>
                  <td colSpan={4}>
                    <Box py={5}><TableLoader /></Box>
                  </td>
                </tr>
              ) : (
                items.length ? (
                  items.map(data => <SubOneTableItem key={data.Id} {...data} />)
                ) : <tr><td colSpan={6} style={{ textAlign: 'center' }}>No data</td></tr>
              )
            }
          </TableBody>
        </Table>
      </Grid>
      <Box pt={1}>
        <Pagination top={pagination.top} skip={pagination.skip} totalCount={countItems} onChange={handleChangePagination} />
      </Box>
    </Grid>
  )
}


SubOneTable.propTypes = {
  loadSubOne: PropTypes.func.isRequired,
  closeGrid: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}).isRequired
}

SubOneTable.defaultProps = {
  items: [],
  countItems: 0,
  isLoading: false
}

const enhance = compose(
  connect((state, { id }) => {
    const { items, countItems, isLoading } = getGridById(state, { gridId: `${ACCOUNT_SUB_LIST_GRID}_${id}` })
    return {
      items,
      countItems,
      isLoading
    }
  }, {
    loadSubOne: loadSubOneController,
    closeGrid: closeGridController,
    updateListSubAccount: updateListSubAccountController
  })
)
export default enhance(SubOneTable)

