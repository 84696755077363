import React from 'react';
import { TextField } from '@material-ui/core';

const TextFieldConnected = props => {
  return (
    <TextField 
      {...props}
      {...props.input}
      label={props.label}
      type={props.type}
      placeholder={props.placeholder}
      variant={props.variant}
      fullWidth={props.fullWidth}
      error={!!(props.meta.touched && props.meta.error)}
    />   
  );
};

export default TextFieldConnected;
