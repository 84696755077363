import React from 'react'
import SubTwo from '@components/AccountComponent/components/TableAccount/subComponents/SubTwo'
import { Box, Button, Typography } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const TableTransactionsCurrency = ({ match: { params: { id }}}) => {
  let history = useHistory();
  return (
    <>
      <Box pt={2}>
        <Button variant='contained' color="primary" onClick={() => history.goBack()} 
         startIcon={<ArrowBackIcon />}
        >
          <Typography>Back</Typography>
        </Button>
      </Box>
      <Box pt={2}>
        <SubTwo id={id}/>
      </Box>
    </>
  )
}

export default TableTransactionsCurrency

