 const CLOSE_MODAL = 'CLOSE_MODAL';
 
 const getModalSelector = state => state.modal

const closeModalController = () => (dispatch) => {
  dispatch({type: CLOSE_MODAL})
}

export {
  getModalSelector,
  closeModalController
}