import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination as MUPagination, PaginationItem } from '@material-ui/lab/';
import PaginationSelectItem from './PaginationSelectItem/PaginationSelectItem';

class Pagination extends Component {
  getTotalPages = () => {
    const { top, totalCount } = this.props;
    return Math.ceil(totalCount / top, 10);
  }

  getCurrentPage = () => {
    const { top, skip } = this.props;
    return Math.ceil(skip / top) + 1;
  }

  handleChange = (e, page) => {
    const { onChange } = this.props;
    if (page === this.getCurrentPage()) return;
    if (onChange) {
      onChange(e, page);
    }
  }

  render() {
    const { top, skip, totalCount, ...props } = this.props;
    const pageCount = this.getTotalPages();
    const currentPage = this.getCurrentPage();

    return (
      <MUPagination
        count={pageCount}
        page={currentPage}
        className="test-pagination"
        data-total-count={totalCount}
        data-page-size={top}
        data-current-page={currentPage}
        data-total-page={pageCount}
        shape="rounded"
        {...props}
        onChange={this.handleChange}
        renderItem={item => {
          if (item.type === 'end-ellipsis' || item.type === 'start-ellipsis') {
            return <PaginationSelectItem currentPage={currentPage} pageCount={pageCount} onChange={this.handleChange} type={item.type} />;
          }
          return <PaginationItem className={`test-pagination-${item.type}${item.type === 'page' ? ` test-pagination-${item.type}-${item.page}` : ''}`} {...item} />;
        }}
      />

    );
  }
}

Pagination.propTypes = {
  top: PropTypes.number,
  skip: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func
};

Pagination.defaultProps = {
  top: 10,
  skip: 0,
  totalCount: 9999999,
  onChange: null
};

export default Pagination;
