import React, { useContext, useState } from 'react';
import { Button, Box, Typography } from '@material-ui/core';

import { dispatch } from '@store/store'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types'
import { reduxForm, getFormValues, getFormSyncErrors } from 'redux-form';

import { PopupContext } from '@context/PopupContext';

import { Validate, Normalize } from '@helper/Validate';

import {
  getGenerationUrlController,
  activateAccountController
} from '../controller'

import TextField from '@helper/reduxForm/TextField/TextField'

const CLOSE_MODAL = 'CLOSE_MODAL';


const GenerationUrlAccountComponentModal = ({ modalProps: { id, marketplace }, getGenerationUrl, activateAccount, values, formSyncErrors }) => {
  const { openPopup } = useContext(PopupContext)
  const [url, setUrl] = useState(null)

  const isDisabledButton = !!(formSyncErrors && Object.keys(formSyncErrors).length);

  const generationUrl = async () => {
    const resultUrl = await getGenerationUrl(openPopup, id, marketplace)
    setUrl(resultUrl.url)
  }

  const activate = () => {
    activateAccount(openPopup, { id, code: values.code })
  }

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL });
  }

  return (
    <>
      <Typography variant={'h4'}>Activation</Typography>
      <Box p={2}>
        <form>
          {
            url && (
              <Box pb={2}>
                <Typography><a href={url} target="_blank" style={{ wordWrap: 'break-word' }} rel="noreferrer">{url}</a></Typography>
              </Box>
            )
          }
          <Box pb={1}>
            <Button variant="contained" onClick={generationUrl}>Generation new url</Button>
          </Box>
          <Box pb={1}>
            <TextField name="code" label="code" variant="outlined" fullWidth validate={[Validate.required]} normalize={Normalize.trim} />
          </Box>
          <Box pt={2} display="flex" justifyContent="center">
            <Box pr={2}>
              <Button variant="contained" color="primary" startIcon={<AddCircleIcon />} onClick={activate} disabled={isDisabledButton}>Activate</Button>
            </Box>
            <Box>
              <Button variant="contained" color="secondary" onClick={closeModal}>Close</Button>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  )
}

GenerationUrlAccountComponentModal.propTypes = {
  modalProps: PropTypes.shape(),
  getGenerationUrl: PropTypes.func.isRequired,
  activateAccount: PropTypes.func.isRequired,
}

GenerationUrlAccountComponentModal.defaultProps = {
  modalProps: {}
}

const enhance = compose(
  reduxForm({
    form: 'GENERATION_URL_ACCOUNT_FORM',
  }),
  connect(state => {
    return {
      values: getFormValues('GENERATION_URL_ACCOUNT_FORM')(state),
      formSyncErrors: getFormSyncErrors('GENERATION_URL_ACCOUNT_FORM')(state),
      modalProps: state.modal.props
    }
  }, {
    getGenerationUrl: getGenerationUrlController,
    activateAccount: activateAccountController
  })
)

export default enhance(GenerationUrlAccountComponentModal)
