import React, { useContext } from 'react';
import { Button, Box, Typography } from '@material-ui/core';

import { dispatch } from '@store/store'
import DeleteIcon from '@material-ui/icons/Delete';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types'


import { deleteAccountController } from '../controller'
import { PopupContext } from '@context/PopupContext';

const CLOSE_MODAL = 'CLOSE_MODAL';

const DeleteAccountModal = ({ deleteAccount, modalProps: { guid } }) => {
  const { openPopup } = useContext(PopupContext)

  const handleDelete = (e) => {
    e.preventDefault();
    deleteAccount(guid, openPopup)
  }

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL });
  }

  return (
    <div>
      <Typography variant={'h4'}>Delete Account</Typography>
      <Box p={4}>
        <form onSubmit={handleDelete}>
          <Box pt={2} display="flex" justifyContent="center">
            <Box pr={2}><Button variant="contained" color="secondary" onClick={closeModal}>Cancel</Button></Box>
            <Box><Button variant="contained" color="primary" type="submit" startIcon={<DeleteIcon />} onClick={handleDelete}>Delete</Button></Box>
          </Box>
        </form>
      </Box>
    </div>
  )
}

DeleteAccountModal.propTypes = {
  deleteAccount: PropTypes.func.isRequired,
  modalProps: PropTypes.shape()
}

DeleteAccountModal.defaultProps = {
  modalProps: {}
}

const enhance = compose(
  connect(state => {
    return {
      modalProps: state.modal.props
    }
  }, {
    deleteAccount: deleteAccountController
  })
)
export default enhance(DeleteAccountModal)
