import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { loadSyncController, updateListSyncAccountController, } from './controller'

import { PushTransactionsController } from '@components/AccountComponent/controller'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Box, Button } from '@material-ui/core'
import CheckAll from '@helper/CheckAll/CheckAll'
import Pagination from '@helper/Pagination/Pagination';
import SubTwoTableItem from './SubTwoTableItem';
import SendIcon from '@material-ui/icons/Send';

import TableLoader from '@helper/Table/components/TableLoader';
import { ACCOUNT_SYNC_LIST_GRID } from '@components/AccountComponent/components/TableAccount/subComponents/constants'

import { closeGridController, getGridById } from '@root/controller'

import { toggleSort } from '@root/helperFunctions'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import SearchTextField from '@helper/SearchTextField/SearchTextField'
import SearchSelect from '@helper/SearchSelect/SearchSelect'

import HeadStyledTableCell from '@components/AccountComponent/components/TableAccount/Template/HeadStyledTableCell'
import { useStyles } from '@components/AccountComponent/styles';


const SubTwoTable = ({
  loadSync,
  closeGrid,
  synchronizationId,
  items,
  countItems,
  isLoading,
  updateListSyncAccount,
  PushTransactions,
  initialValues: { fieldSort, typeSort, top, skip }
}) => {
  const classes = useStyles()
  const [sort, setSort] = useState({ field: fieldSort, type: typeSort })
  const [pagination, setPagination] = useState({ top: top, skip: skip })
  const [valueStringFilter, setValueStringFilter] = useState(null)

  const [displayBtnForceWB, setDisplayBtnForceWB] = useState(true)


  const [isAllChecked, setIsAllChecked] = useState(false)
  const [checkboxes, setCheckboxes] = useState(null)
  const handleCheck = (e, id) => {
    setCheckboxes({ ...checkboxes, [id]: e.currentTarget.checked })
  }

  const checkedAll = () => {
    const allCheckbox = items.reduce((acc, item) => {
      acc[item.Id] = !isAllChecked
      return acc
    }, {})

    setCheckboxes(allCheckbox)
    setIsAllChecked(!isAllChecked)
  }

  const handlePushTransactions = () => {
    PushTransactions(Object.entries(checkboxes).filter(el => el[1]).map(el => el[0]), `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}`, startQuery, synchronizationId)
  }

  useEffect(() => {
    if (checkboxes && Object.entries(checkboxes).filter(el => el[1]).length > 0) {
      setDisplayBtnForceWB(false)
    } else {
      setDisplayBtnForceWB(true)
    }
  }, [checkboxes])

  const startQuery = `?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.skip}&$count=true`

  const handleClickHead = e => {
    const th = e.target.closest('th')
    if (th.hasAttribute('data-sort') && th.getAttribute('data-sort') === 'true') {
      setSort({ field: th.getAttribute('data-head-name'), type: toggleSort(sort.type) })
      updateListSyncAccount(`?$orderby=${th.getAttribute('data-head-name')} ${toggleSort(sort.type)}&$top=${top}&$skip=${skip}&$count=true${valueStringFilter ? '&$filter=' + valueStringFilter : ''}`, synchronizationId)
      setPagination({ top, skip })
    }
  }

  const handleChangePagination = (e, page) => {
    setCheckboxes(null)
    setIsAllChecked(false)
    setPagination({ ...pagination, skip: pagination.top * page - pagination.top })
    updateListSyncAccount(`?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true${valueStringFilter ? '&$filter=' + valueStringFilter : ''}`, synchronizationId)
  }

  const [uf, setUf] = useState('')
  const updateFilter = (value, filter, filed) => {
    setUf(state => ({ ...state, [filed]: { type: filter, value: value } }))

    const objFilters = { ...uf, [filed]: { type: filter, value: value } }
    let arr = [];
    for (const key in objFilters) {
      var filterValue = objFilters[key].value;

      if ((!filterValue && objFilters[key].value !== 0)
        || objFilters[key].value === `'All'`) {
        continue
      }

      if (typeof filterValue === 'string' || filterValue instanceof String) {
        filterValue = "'" + filterValue.replace(/^'+|'+$/gi, '') + "'";
      }

      if (objFilters[key].type === 'contains') {
        arr.push(`contains(${key}, ${filterValue})`)
      } else {
        arr.push(`${key} ${objFilters[key].type} ${filterValue}`)
      }
    }

    const doneStringFilter = arr.join(' and ')
    setValueStringFilter(doneStringFilter)
    updateListSyncAccount(
      `?$orderby=${sort.field} ${sort.type}&$top=${top}&$skip=${skip}&$count=true`
      + `${doneStringFilter ? `&$filter=${encodeURIComponent(doneStringFilter)}` : ''}`,
      synchronizationId)
    setPagination({ top, skip })
  }

  useEffect(() => {
    loadSync(synchronizationId, startQuery)
    return () => {
      closeGrid({ gridId: `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}` })
    }
  }, [])

  const configHeadTitle = [
    {
      width: 50, titleColumn: 'CheckAll',
      customComponent: <CheckAll checkedAll={checkedAll} isAllChecked={isAllChecked} />
    },
    {
      width: 150, titleColumn: 'Paid',
      titleColumnSort: 'Paid', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 150, titleColumn: 'Status',
      titleColumnSort: 'Status', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchSelect updateFilter={(text, filter) => updateFilter(text, filter, 'Status')} filterString="string" options={[
        { label: 'All', value: '' },
        { label: 'New', value: 'New' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Completed', value: 'Completed' },
        { label: 'Failed', value: 'Failed' },
        { label: 'Ignored', value: 'Ignored' }
      ]} />
    },
    {
      width: 150, titleColumn: 'Transaction Id',
      titleColumnSort: 'TransactionId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'TransactionId')} filterString="string" />
    },
    {
      width: 150, titleColumn: 'Listing Id',
      titleColumnSort: 'ListingId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'ListingId')} filterString="string" />
    },
    {
      width: 150, titleColumn: 'Order Id',
      titleColumnSort: 'OrderId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'OrderId')} filterString="string" />
    },
    {
      width: 150, titleColumn: 'Receipt Id',
      titleColumnSort: 'ReceiptId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'ReceiptId')} filterString="number" />
    },
    {
      width: 150, titleColumn: 'Amount Gross',
      titleColumnSort: 'AmountGross', isSort: true, currentSortField: sort.field, sortType: sort.type,
    },
    {
      width: 200, titleColumn: 'Provider Account Name',
      titleColumnSort: 'ProviderAccountName', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'ProviderAccountName')} filterString="string" />
    },
    {
      width: 200, titleColumn: 'Customer Email',
      titleColumnSort: 'CustomerEmail', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'CustomerEmail')} filterString="string" />
    },
    {
      width: 200, titleColumn: 'Customer Name',
      titleColumnSort: 'CustomerName', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 150, titleColumn: 'Title',
      titleColumnSort: 'Title', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 80, titleColumn: 'Upload Retries',
      titleColumnSort: 'UploadRetries', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 80, titleColumn: 'Upload Attempt',
      titleColumnSort: 'UploadAttempt', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      titleColumnSort: 'Error', isSort: true, currentSortField: sort.field, width: 150, titleColumn: 'Error', sortType: sort.type
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.hoverHead} onClick={handleClickHead}>
              {
                configHeadTitle.map(({ titleColumnSort, isSort, currentSortField, width, titleColumn, sortType }) => {
                  return (
                    <HeadStyledTableCell
                      key={titleColumn}
                      titleColumnSort={titleColumnSort}
                      isSort={isSort}
                      currentSortField={currentSortField}
                      width={width}
                      widthPx={true}
                      titleColumn={titleColumn}
                      sortType={sortType}
                    />
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <tr>
              {
                configHeadTitle.map(({ titleColumn, filterComponent = null, customComponent = null }) => <td key={titleColumn}>
                  {customComponent ? customComponent : (
                    filterComponent ? filterComponent : null
                  )}
                </td>)
              }
            </tr>
            {
              isLoading ? (
                <tr>
                  <td colSpan={15}>
                    <Box py={15}><TableLoader /></Box>
                  </td>
                </tr>
              ) : (
                items.length ? (
                  items.map(data => <SubTwoTableItem key={data.Id} {...data} handleCheck={handleCheck} checkboxes={checkboxes} />)
                ) : <tr><td colSpan={15} style={{ textAlign: 'center' }}>No data</td></tr>
              )
            }
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Box pl={4} py={3}>
              <Button variant="contained" size="small" color="primary" startIcon={<SendIcon />} onClick={handlePushTransactions} disabled={displayBtnForceWB}>
                Deposit transactions to user account in WB
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box pt={1}>
        <Pagination top={pagination.top} skip={pagination.skip} totalCount={countItems} onChange={handleChangePagination} />
      </Box>
    </Grid>
  )
}

SubTwoTable.propTypes = {
  loadSync: PropTypes.func.isRequired,
  synchronizationId: PropTypes.string.isRequired,
  updateListSyncAccount: PropTypes.func.isRequired,
  PushTransactions: PropTypes.func.isRequired,
}

SubTwoTable.defaultProps = {
  items: [],
  countItems: 0,
  isLoading: false,
}

const enhance = compose(
  connect((state, { synchronizationId }) => {
    const { items, countItems, isLoading } = getGridById(state, { gridId: `${ACCOUNT_SYNC_LIST_GRID}_${synchronizationId}` })
    return {
      items,
      countItems,
      isLoading
    }
  }, {
    loadSync: loadSyncController,
    closeGrid: closeGridController,
    updateListSyncAccount: updateListSyncAccountController,
    PushTransactions: PushTransactionsController
  })
)

export default enhance(SubTwoTable)
