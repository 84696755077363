import React from 'react'
import SubTwoTable from './SubTwoTable';

const initialValues = {
  fieldSort: 'Paid',
  typeSort: 'desc',
  top: 20,
  skip: 0
}

const SubTwo = ({ id }) => <SubTwoTable synchronizationId={id} initialValues={initialValues} />

export default SubTwo
