const initState = { 
  openModal: false,
  contentModal: null,
  props: null
}

export const modal = (state = initState, action) => {
  switch (action.type) {
    case "INIT_MODAL": {
      return {
        ...state,
        openModal: true,
        contentModal: action.payload.nameModal,
        props: action.payload.props
      }
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        openModal: false,
        contentModal: null,
        props: null
      }
    }
    default: {
      return state
    }
  }
}
