import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Box, Menu } from '@material-ui/core';

import { FormControlLabel, Switch, Grid } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const HelperBtn = ({
  id,
  enabled,
  handleActivate,
  handleEdit,
  handleChangeSwitch,
  handleDeleteAccount,
  handleForceSync,
  tokenType,
  openSyncTable,
  openTransactionTable,
  marketplace,
  activate
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const Activate = () => {
    handleClose()
    handleActivate(id)
  }

  const ActivateHook = () => {
    handleClose()
    activate()
  }

  const Edit = () => {
    handleClose()
    handleEdit(id)
  }

  const ChangeSwitch = () => {
    handleClose()
    handleChangeSwitch(enabled, marketplace, id)
  }

  const DeleteAccount = () => {
    handleClose()
    handleDeleteAccount(id)
  }

  const ForceSync = () => {
    handleClose()
    handleForceSync(id)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSyncTable = () => {
    openSyncTable()
  }
  const handleOpenTransactionTable = () => {
    openTransactionTable()
  }

  return (
    <>
      <IconButton
        style={{ padding: 0 }}
        aria-label="more"
        aria-controls="long-menu"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id={`customized-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box p={1}>
          <Grid container spacing={1} direction="column">
            {
              marketplace.includes('Shopify') ? (
                <Grid item><Button fullWidth variant="contained" size="small" color="primary" startIcon={<CheckCircleOutlineIcon />} onClick={ActivateHook}>Activate</Button></Grid>
              ) : (
                tokenType !== 'Permanent' ? (
                  <Grid item><Button fullWidth variant="contained" size="small" color="primary" startIcon={<CheckCircleOutlineIcon />} onClick={Activate}>Activation</Button></Grid>
                ) : null
              )
            }
            {
              enabled && tokenType === 'Permanent'? (
                <Grid item><Button onClick={ForceSync} variant="contained" size="small" color="primary" startIcon={<SendIcon />}>Start synchronization</Button></Grid>
              ) : null
            }
            <Grid item><Button fullWidth onClick={Edit} variant="contained" size="small" color="primary" startIcon={<CreateIcon />}>Edit</Button></Grid>
            <Grid item><Button fullWidth onClick={handleOpenSyncTable} variant="contained" size="small" color="primary">Synchronization table</Button></Grid>
            <Grid item><Button fullWidth onClick={handleOpenTransactionTable} variant="contained" size="small" color="primary">Transaction table</Button></Grid>
            <Grid item><Button fullWidth variant="contained" size="small" color="secondary" startIcon={<DeleteIcon />} onClick={DeleteAccount}>Delete</Button></Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={enabled} onChange={ChangeSwitch} name="ChangeSwitch" />}
                label={enabled ? 'Enable' : 'Disabled'}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledMenu>
    </>
  )
}

export default HelperBtn;
