import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select, InputBase, Box } from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';


const styles = {
  Select: {
    '& .MuiSelect-select': {
      paddingLeft: 8,
      paddingTop: 4,
      paddingBottom: 5,
      '&:hover': {
        background: 'rgba(0,0,0,.1)'
      },
      '&:focus': {
        background: 'rgba(0,0,0,.1)'
      }
    },
  },
  SelectItem: {
    cursor: 'pointer',
    padding: 4,
    '&:hover': {
      background: 'rgba(0,0,0,.1)'
    }
  }
};
const getOptions = ({ currentPage, pageCount, classes, type }) => {
  const options = [];
  if (type === 'end-ellipsis') {
    const firstPage = currentPage >= 5 ? currentPage + 2 : 6;
    for (let i = firstPage; i < pageCount; i += 1) {
      options.push({
        label: i,
        value: i
      });
    }
  }

  if (type === 'start-ellipsis') {
    const lastPage = pageCount - currentPage < 4 ? pageCount - 4 : currentPage - 1;
    for (let i = 2; i < lastPage; i += 1) {
      options.push({
        label: i,
        value: i
      });
    }
  }
  return (
    options.map(({ label, value }) => (
      <Box
        textAlign="center"
        key={value}
        value={value}
        className={classes.SelectItem}
      >
        {label}
      </Box>
    ))
  );
};

class PaginationSelectItem extends PureComponent {
  handleChange = e => {
    const { onChange } = this.props;
    const { target: { value } } = e;
    onChange(e, value);
  }

  render() {
    const { classes, currentPage, pageCount, type } = this.props;
    const options = getOptions({ currentPage, pageCount, classes, type });
    return (
      <Select
        labelId="pagination-select-label"
        id="select-end-ellipsis"
        input={<InputBase color="secondary" />}
        value="dots"
        MenuProps={{
          elevation: 1,

          MenuListProps: {
            component: Box,
          },
          PaperProps: {
            style: {

            }
          }
        }}
        onChange={this.handleChange}
        className={classes.Select}
      >
        <Box
          display="none"
          value="dots"
        >
          ...
        </Box>
        ))
        {options}
      </Select>
    );
  }
}

PaginationSelectItem.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['start-ellipsis', 'end-ellipsis']).isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(PaginationSelectItem);
