import React from 'react'
import AdminTable from './components/AdminTable/AdminTable'
import { Box, Button, Typography } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { openModalController } from './controller';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';


const CREATE_ADMIN_MODAL = 'CREATE_ADMIN_MODAL';

 const AdminComponent = ({ openModal }) => {

  const handleOpen = () => {
    openModal({nameModal: CREATE_ADMIN_MODAL})
  };

  return (
    <Box>
      <Box py={2}>
        <Button variant="contained" size="small"color="primary" startIcon={<AddCircleIcon />} onClick={handleOpen}>
          <Typography>Create new Admin</Typography>
        </Button>
      </Box>
      <Box py={2}>
        <AdminTable />
      </Box>
    </Box>
  )
}

AdminComponent.propTypes = {
  openModal: PropTypes.func.isRequired
}


const enhance = compose(
  connect(null, {
    openModal: openModalController
  })
)

export default enhance(AdminComponent)
