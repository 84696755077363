import { createSelector } from 'reselect';

const closeGridController = gridId => (dispatch) => {
  dispatch({type: 'CLOSE_GRID', payload: gridId})
}

const getGrids = state => state.grids;

const getGridById = createSelector([getGrids, (_, props) => props.gridId], (gridsArray, gridId) => gridsArray.find(grid => grid.gridId === gridId) || {});



export {
  closeGridController,
  getGridById
}