
import React, { useState, useContext } from 'react';
import TextField from '@helper/reduxForm/TextField/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Box, Button, Typography, Container, Avatar } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useStyles } from './styles';
import InputAdornment from '@material-ui/core/InputAdornment';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { checkAuthController } from '@pages/authorization/controller'

import { PopupContext } from '@context/PopupContext'

import { reduxForm, getFormValues, SubmissionError } from 'redux-form';
import { AUTH_FORM } from '@pages/authorization/constants'

import { Validate, Normalize } from '@helper/Validate'
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const AuthorizationPage = ({ history: { push }, checkAuth, handleSubmit, loading }) => {

  const { openPopup } = useContext(PopupContext)
  const classes = useStyles();


  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async ({ email, password }) => {
    await checkAuth(email, password, openPopup, push)
  };


  const submit = values => new Promise((resolve, reject) => {
    let errors;
    for (const [key, value] of Object.entries(values)) {
      if (!value || ((typeof value === 'string') && !value.length) || value === 'false') {
        errors = {
          ...errors,
          [key]: 'Required field'
        };
      }
    }
    if (errors) {
      reject(new SubmissionError({ ...errors }));
    } else {
      resolve(onSubmit(values));
    }
  });

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Box pb={5}>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
          </Box>
          <form className={classes.form} onSubmit={handleSubmit(submit)}>
            <Box pb={1}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                validate={[Validate.required, Validate.email]}
                normalize={Normalize.trim}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box pb={1}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                validate={[Validate.required]}
                normalize={Normalize.trim}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box pb={1}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Sign in
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </>
  );
}

AuthorizationPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  checkAuth: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string
  }),
}

AuthorizationPage.defaultProps = {
  values: {},
  loading: false

}

const enhance = compose(
  reduxForm({
    form: AUTH_FORM,
    initialValues: {
      email: null,
      password: null
    },
    touchOnChange: true,
  }),
  connect(state => ({
    values: getFormValues(AUTH_FORM)(state),
    loading: state.user.loading
  }), {
    checkAuth: checkAuthController
  })
)
export default enhance(AuthorizationPage);

