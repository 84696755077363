const initState = []

export const grids = (state = initState, action) => {
  
  switch (action.type) {
    case "INIT_GRID": {
      const { gridId, ...props } = action.payload;
      return [
        ...state, 
        {
          gridId,
          ...props
        }
      ]
    }
    case "UPDATE_GRID": {
      const { gridId, items, ...props } = action.payload;
      return state.map(grid => {
        if (grid.gridId === gridId) {
          return {
            ...grid,
            items,
            ...props
          };
        }
        return grid;
      });
    }
    case "CLOSE_GRID": {
      const { gridId } = action.payload;
      return state.filter(grid => grid.gridId !== gridId);
    }
    case "LOADING_GRID": {
      const { gridId, isLoading } = action.payload;
      return state.map(grid => {
        if (grid.gridId === gridId) {
          return {
            ...grid,
            isLoading
          };
        }
        return grid;
      });
    }
    default: {
      return state
    }
  }
}
