import axios from '@helper/Api/api';

const getAdminsListApi = str => axios.get(`/api/v1/odata/Admins${str}`, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const createAdminApi = data => axios.post('/api/v1/admins', data, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const editPasswordAdminApi = (id, { password }) => axios.post(`/api/v1/admins/${id}/password`, {password}, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)
const deleteAdminApi = id => axios.delete(`/api/v1/admins/${id}`, { headers: { authorization: 'Bearer ' + localStorage.getItem('token') } }).then(result => result.data)


export {
  getAdminsListApi,
  createAdminApi,
  editPasswordAdminApi,
  deleteAdminApi
}
